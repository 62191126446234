import React, { Component } from 'react';
import { FirestoreDocument } from 'react-firestore';
import dayjs from 'dayjs';
import Container from '@material-ui/core/Container';
import Spinner from '../components/Spinner';

class pendingPost extends Component {

  render() {
    const { postId } = this.props.match.params;

    return (
      <div className='align-center'>
        <Container maxWidth='md'>
          <FirestoreDocument
            path={'pendingPosts/' + postId}
            render={({ isLoading, data }) => {
              return isLoading ? (
                <Spinner />
              ) : (
                  <div className='content-wrapper'>
                    <div className='post-detail__header'>
                      { data.title &&
                        <h1>{ data.title }</h1>
                      }

                      <p><strong>{ data.user }</strong></p>
                      <p><i>{ dayjs(data.createdAt).format('DD.MM.YYYY') }</i></p>

                      { data.location &&
                        <p>{ data.location }</p>
                      }

                      { data.url &&
                        <a
                          href={ data.url }
                          target='_blank'
                          rel='noopener noreferrer'
                        ><i>{ data.url }</i></a>
                      }
                    </div>

                    { data.imageUrl &&
                      <img
                        src={ data.imageUrl }
                        alt=''
                      />
                    }

                    { data.sound &&
                      <a
                        href={ data.sound }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='lg'
                      >
                        { data.sound }
                      </a>
                    }

                    { data.video &&
                      <a
                        href={ data.video }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='lg'
                      >
                        { data.video }
                      </a>
                    }

                    { data.message &&
                      <p className='post-message'>{ data.message }</p>
                    }

                    { data.freeMessage &&
                      <div className='post-detail__free-message-wrapper'>
                        <h3>Freitextfeld / Kommentare von { data.user }</h3>
                        <div className='post-detail__free-message'>
                          <p>{ data.freeMessage }</p>
                        </div>
                      </div>
                    }
                  </div>
                );
            }}
          />

        </Container>
      </div>
    );
  }
}

export default pendingPost;
