import React, { Component, Suspense } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
class about extends Component {

	render() {
		const { t } = this.props;

		return (
			<div className='sctn sctn--nt'>
				<Container maxWidth='md'>
					<div className='content-wrapper'>
			      <div className='align-center'>
							<h1>
								<Trans i18nKey="about.title"/>
				      </h1>
				    </div>
				    <div>
				      <Grid
				      	container
				      	spacing={ 3 }
				      >
					      <Grid
					      	item
					      	sm={ 12 }
					      >
									<p>
				        		{ t('about.text1') }
				      		</p>

				      		<div className='align-center'>
										<h2 className='about-sec'>
							      	{ t('about.title-2') }
							      </h2>
							    </div>
									<p>
										<strong>{ t('about.text2-title') }</strong><br />
				        		{ t('about.text2') }
				      		</p>
									<p>
										<strong>{ t('about.text3-title') }</strong><br />
				        		{ t('about.text3') }
				      		</p>
									<p>
										<strong>{ t('about.text4-title') }</strong><br />
				        		{ t('about.text4') }
				      		</p>
									<p>
										<strong>{ t('about.text5-title') }</strong><br />
				        		{ t('about.text5') }
				      		</p>
									<p>
										<strong>{ t('about.text6-title') }</strong><br />
				        		{ t('about.text6') }
				      		</p>
									<p>
										<strong>{ t('about.text7-title') }</strong><br />
				        		{ t('about.text7') }
				      		</p>
									<p>
										<strong>{ t('about.text8-title') }</strong><br />
				        		{ t('about.text8') }<br />
				        		<a
				        			href='mailto:anotherspring@dfh-ufa.org'
				        			target='_blank'
				        			rel='noopener noreferrer'
				        		>anotherspring@dfh-ufa.org</a>
				      		</p>
				      	</Grid>
				    	</Grid>
				  	</div>
				  </div>
				</Container>
			</div>
		)
	}
}

const MyComponent = withTranslation()(about);
// export default about;

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback='loading'>
      <MyComponent />
    </Suspense>
  );
}
