import React, { Component } from 'react';
import { FirestoreDocument } from 'react-firestore';
import dayjs from 'dayjs';
import Container from '@material-ui/core/Container';
import Spinner from '../components/Spinner';

class post extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { postId } = this.props.match.params;

    return (
      <div className='align-center'>
        <Container maxWidth='md'>
          <FirestoreDocument
            path={"posts/" + postId}
            render={({ isLoading, data }) => {
              return isLoading ? (
                <Spinner />
              ) : (
                  <div className="content-wrapper">
                    <div className='post-detail__header'>
                      { data.title &&
                        <h1>{ data.title }</h1>
                      }

                      <p><strong>{ data.user }</strong></p>
                      <p><i>{ dayjs(data.createdAt).format('DD.MM.YYYY') }</i></p>

                      { data.location &&
                        <p>{ data.location }</p>
                      }

                      { data.url &&
                        <a
                          rel="noopener noreferrer"
                          href={ data.url }
                          target='_blank'
                        ><i>{ data.url }</i></a>
                      }
                    </div>

                    { data.imageUrl &&
                      <div className='image-wrapper'>
                        <img
                          src={ data.imageUrl }
                          alt={ data.title && data.title }
                          className='lazyload'
                        />
                      </div>
                    }

                    { data.sound &&
                      <a
                        rel="noopener noreferrer"
                        href={ data.sound }
                        target='_blank'
                        className='lg'
                      >
                        { data.sound }
                      </a>
                    }

                    { data.video &&
                      <a
                        rel="noopener noreferrer"
                        href={ data.video }
                        target='_blank'
                        className='lg'
                      >
                        { data.video }
                      </a>
                    }

                    { data.message &&
                      <p className="post-message">{ data.message }</p>
                    }
                  </div>
                );
            }}
          />

        </Container>
      </div>
    );
  }
}

export default post;
