import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from '../images/logo-nautile.svg'

const Header = () => {
	const { t } = useTranslation();

	return (
		<header className='header'>
			<div className="sctn">
				<Container>
					<Grid
					  container
					  justify='space-between'
					  alignItems='flex-end'
					>
						<Grid item>
							<Link
								to='/create-selection'
								className='header__left'
							>
								<Button
									variant='outlined'
									color='primary'
								>
									{ t('pages.post') }
								</Button>
							</Link>
						</Grid>
						<Grid item>
							<NavLink
								to='/'
								className='header__center'
							>
								<img
									src={ logo }
									alt='Logo'
								/>
							</NavLink>
						</Grid>
						<Grid item>
							<NavLink
								to='/about'
								activeStyle={{ textDecoration: "underline" }}
								className='header__right'
							>
								{ t('pages.about') }
							</NavLink>
							<NavLink
								to='/tandem-nautile'
								activeStyle={{ textDecoration: "underline" }}
								className='header__right hide-mobile'
							>
								{ t('pages.tandem_nautile') }
							</NavLink>
						</Grid>
				  </Grid>
				</Container>
			</div>
		</header>
	);
};

export default Header;
