import * as firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCyaBLZxxZilrdeJGK7EEXqvvnE3lb4Q1k",
    authDomain: "dfh-web-application.firebaseapp.com",
    databaseURL: "https://dfh-web-application.firebaseio.com",
    projectId: "dfh-web-application",
    storageBucket: "dfh-web-application.appspot.com",
    messagingSenderId: "27539509762",
    appId: "1:27539509762:web:61aaf83c2ebddc09a30d1b",
    measurementId: "G-VKTMP48E1S"
  };

export const app = firebase.initializeApp(firebaseConfig);
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const db = firebase.firestore();
