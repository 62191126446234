import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';

class terms extends Component {
	render() {
		const { t } = this.props;

		return (
			<div className='sctn sctn--nt'>
				<Container maxWidth='md'>
					<div className="content-wrapper">
			      <div className='align-center'>
							<h1>
				      	{ t('pages.terms') }
				      </h1>
				    </div>
				    <div>
				    	<p>
				    		{ t('terms-and-conds') }
				    	</p>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}

const MyComponent = withTranslation()(terms);
// export default about;

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyComponent />
    </Suspense>
  );
}
