import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { app } from "./Firebase";
import { Link } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import store from './redux/store';

// components
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from "./components/ScrollToTop";

// pages
import about from './pages/about';
import tandem_nautile from './pages/tandem-nautile';
import create_selection from './pages/create-selection';
import create_post from './pages/create-post';
import create_tandem_nautile from './pages/create-tandem-nautile';
import Home from './pages/home';
import login from './pages/login';
import Panel from './pages/panel';
import post from './pages/post';
import pendingPost from './pages/pendingPost';
import tandemNautilePendingPost from './pages/tandemNautilePendingPost';
import privacy from './pages/imprint-and-privacy';
import terms from './pages/terms-and-conditions';
import termsTandemNautile from './pages/terms-and-conditions-tandem-nautile';
import success from './pages/success';
import notFound from './pages/notFound';

// firestore
import firebase from "@firebase/app";
import { FirestoreProvider } from "react-firestore";
import "./Firebase"; // necessary to init firebase, will be later used for logging in to admin panel
import "@firebase/firestore";
import { AuthProvider } from './Auth';
import PrivateRoute from "./PrivateRoute";

// lazy sizes / lazy loading images
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

const App = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
    	if(user) {
        // array of admin IDs
		    const admin = [
		      'PLQ5YLz05xUUQh2lse0iD4BZ4622', // berthomier@dfh-ufa.org
		      'QGtCyERZXsgll3XxjPywDt2Lijh1', // nestler@dfh-ufa.org
		      'mZwHGEBMjRgFpImHcNbwrMYdABq1', // susanne.rockweiler@googlemail.com
		      '2fLpzMfQyNab4LOthGTLd5vCkaz2', // hinz@dfh-ufa.org
		      'dkeVe6oqlJS9uENVFrNLQSNosyO2', // spreitzer@dfh-ufa.org
		      '0GIhZG9QCUM9k7r9Ifdk9SasEKp1', // tobi@muse-case.com
          'cyvRzsE4qXVhvoy5NrUEhesjGvq2', // nicole.martinez@institutfrancais.de
          'O5HYDzYsaXcLHzEIKFKqkQKFo5G3'  // barbier@dfh-ufa.org
		    ].includes(window.localStorage.getItem('user'));

	      if (admin) {
          setIsAdmin(true);
	      }
        setIsLoggedIn(true);
    	}
    });
  }, []);

  function handleLogout() {
    // this.props.logoutUser();
    app.auth().signOut();
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  return (
    <FirestoreProvider firebase={firebase}>
      <Provider store={store}>
        <AuthProvider>
          <Router>
            { isLoggedIn && (
              <div className='fixed-user-bar'>
                <p>
                  {'Logged in as '}
                  <strong>{ app.auth().currentUser.email }</strong>
                </p>
                <Link
                  to='/panel'
                  className="btn"
                >
                  Admin Panel
                </Link>
                <div
                  value='Logout'
                  className="btn btn--warning"
                  onClick={ () => handleLogout() }
                >
                  Logout
                </div>
              </div>
            )}
            <Header />
            <ScrollToTop>
              <Switch>
                <Route
                  exact
                  path='/'
                  component={() => <Home
                    isAdmin={ isAdmin }
                    isLoggedIn={ isLoggedIn }
                  />
                }  />
                <Route
                  exact
                  path='/post/:postId'
                  component={post}
                />
                <PrivateRoute
                  exact
                  path='/pendingPost/:postId'
                  component={pendingPost}
                />
                <PrivateRoute
                  exact
                  path='/tandemNautilePendingPost/:postId'
                  component={tandemNautilePendingPost}
                />
                <PrivateRoute
                  exact
                  path='/panel'
                  component={ () => <Panel isAdmin /> }
                />
                <Route
                  exact
                  path='/login'
                  component={login}
                />
                <Route
                  exact
                  path='/about'
                  component={about}
                />
                <Route
                  exact
                  path='/tandem-nautile'
                  component={tandem_nautile}
                />
                <Route
                  exact
                  path='/create-selection'
                  component={create_selection}
                />
                <Route
                  exact
                  path='/create-post'
                  component={create_post}
                />
                <Route
                  exact
                  path='/create-tandem-nautile'
                  component={create_tandem_nautile}
                />
                <Route
                  exact
                  path='/imprint-and-privacy'
                  component={privacy}
                />
                <Route
                  exact
                  path='/terms-and-conditions'
                  component={terms}
                />
                <Route
                  exact
                  path='/terms-and-conditions-tandem-nautile'
                  component={termsTandemNautile}
                />
                <Route
                  exact
                  path='/success'
                  component={success}
                />
                <Route
                  path='/404'
                  component={notFound}
                />
                <Redirect to='/404' />
              </Switch>
            </ScrollToTop>
            <Footer />
          </Router>
        </AuthProvider>
      </Provider>
    </FirestoreProvider>
  );
}

export default App;
