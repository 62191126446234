import React, { useState } from 'react';
import firebase from "@firebase/app";
import 'firebase/storage'
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import { app } from "../Firebase";

const storage = firebase.storage();

export default function Create() {
	const [image, setImage] = useState('');
	const [progress, setProgress] = useState(0);
	const [mediaIsSelected, setMediaIsSelected] = useState(false);
	const { t } = useTranslation();

	const [soundDisabled, setDisabledSound] = useState(false);
	const [videoDisabled, setDisabledVideo] = useState(false);
	const [messageDisabled, setDisabledMessage] = useState(false);
	const [fileDisabled, setDisabledFile] = useState(false);

	const {
    register,
    handleSubmit,
    errors,
    // setError,
    // clearError,
    formState: { isSubmitting }
  } = useForm();
  const history = useHistory();

	function handleImageChange(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			// this.setState({ image });
			setImage(image);
		}
	}

	function enableAllMedia() {
		setDisabledMessage(false);
		setDisabledVideo(false);
		setDisabledSound(false);
		setDisabledFile(false);
	}

	function removeImage() {
		setImage('');
		enableAllMedia();
	}

  function handleChange(e) {

  	if (e.target.name === 'video' && e.target.value !== '') {
			setDisabledMessage(true);
			setDisabledSound(true);
			setDisabledFile(true);
			setMediaIsSelected(true);
  	} else if (e.target.name === 'sound' && e.target.value !== '') {
			setDisabledMessage(true);
			setDisabledVideo(true);
			setDisabledFile(true);
			setMediaIsSelected(true);
  	} else if(e.target.name === 'message' && e.target.value !== '') {
			setDisabledVideo(true);
			setDisabledFile(true);
			setDisabledSound(true);
			setMediaIsSelected(true);
  	} else if(e.target.name === 'image' && e.target.files.length > 0) {
			setDisabledVideo(true);
			setDisabledMessage(true);
			setDisabledSound(true);
			setMediaIsSelected(true);
  	} else {
  		enableAllMedia();
			setMediaIsSelected(false);
  	}

  	if (e.target.files) {
  		if(e.target.files[0].size > 2000000){
      	alert("File is too big! Max 2MB please!");
      	enableAllMedia();
    	} else {
    		handleImageChange(e);
    	}
  	}
  }

	function handleUpload(data) {
		const date = parseInt(Date.now());
		const uploadTask = storage.ref(`uploads/${date}/${image.name}`).put(image);
		uploadTask.on(
			"state_changed",
			snapshot => {
				const currentProgress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(currentProgress);
				console.log(currentProgress, progress);
			},
			error => {
				console.log(error);
			},
			() => {
				storage
					.ref("uploads")
					.child(date + '/' + image.name)
					.getDownloadURL()
					.then(imageUrl => {
						saveToFirestore(data, imageUrl);
					});
			}
		);
	};

	function saveToFirestore(data, imageUrl) {
		firebase
		.firestore()
		.collection("tandemNautilePendingPosts")
		.doc(require("uuid/v4")())
		.set({
			// student 1
			s1_name: data.s1_name,
			s1_first_name: data.s1_first_name,
			s1_email: data.s1_email,
			s1_phone: data.s1_phone,
			s1_university: data.s1_university,
			s1_degree_program: data.s1_degree_program,
			s1_intended_degree: data.s1_intended_degree,
			s1_head_of_program: data.s1_head_of_program || '',
			s1_website: data.s1_website || '',

			// student 2
			s2_name: data.s2_name,
			s2_first_name: data.s2_first_name,
			s2_email: data.s2_email,
			s2_phone: data.s2_phone,
			s2_university: data.s2_university,
			s2_degree_program: data.s2_degree_program,
			s2_intended_degree: data.s2_intended_degree,
			s2_head_of_program: data.s2_head_of_program || '',
			s2_website: data.s2_website || '',

			// work
			title: data.title,
			imageUrl: imageUrl || '',
			message: data.message || '',
			sound: data.sound || '',
			video: data.video || '',
			freeMessage: data.free_message || '',
			createdAt: parseInt(Date.now()),
		});
		history.push('/success');
	}

	const onSubmit = data => {
		if (mediaIsSelected) {
			if (image === '') {
				saveToFirestore(data);
			} else {
				handleUpload(data);
			}
		}
	};

	return (
		<div>
			<Container maxWidth='md'>
				<div className="headline-wrapper">
					<h1 className='center'>{ t('tandem-nautile.title') }</h1>
					<p className='center'>
						{ t('tandem-nautile.sub-title') }
					</p>
				</div>

				<form
					noValidate
					onSubmit={ handleSubmit(onSubmit) }
				>

					{/*
					*
					*
					* ===============================
					* ===============================  student 1
					* ===============================
					*
					*
					*/}

					<p className='create-step'>1/3</p>
					<h2>{ t('form-field.student_1') }</h2>

					<Grid
						container
						spacing={ 4 }
					>

						{/* ===============================  last name  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_name">
									{ t('form-field.last_name') }*
								</label>
								<input
									type="text"
									name="s1_name"
									maxLength={ 50 }
									className="form__input"
									placeholder={ t('form-field.last_name') }
									ref={ register({ required: true }) }
								/>
								{ errors.s1_name &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  first name  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_first_name">
									{ t('form-field.first_name') }*
								</label>
								<input
									type="text"
									name="s1_first_name"
									maxLength={ 50 }
									className="form__input"
									placeholder={ t('form-field.first_name') }
									ref={ register({ required: true }) }
								/>
								{ errors.s1_first_name &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  email  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_email">
									{ t('form-field.email') }*
								</label>
								<input
									required
									id='email'
									type='email'
									name='s1_email'
									className="form__input"
									variant='outlined'
									placeholder={ t('form-field.email') }
									ref={register({
										required: true,
										pattern: /^([A-Za-z0-9_\-.]){1,}@([A-Za-z0-9_\-.]){1,}\.([A-Za-z]){2,20}$/
									})}
								/>
								{ errors.s1_email &&
									<span className="form__error">
										{ t('form-error.email') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  phone  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_phone">
									{ t('form-field.phone') }*
								</label>
								<input
									type="text"
									name="s1_phone"
									maxLength={ 100 }
									className="form__input"
									placeholder={ t('form-field.phone') }
									ref={ register({ required: true }) }
								/>
								{ errors.s1_phone &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>


						{/* ===============================  university  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_university">
									{ t('form-field.university') }*
								</label>
								<input
									type="text"
									name="s1_university"
									maxLength={ 100 }
									className="form__input"
									placeholder={ t('form-field.university') }
									ref={ register({ required: true }) }
								/>
								{ errors.s1_university &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  degree program  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_degree_program">
									{ t('form-field.degree_program') }*
								</label>
								<input
									type="text"
									name="s1_degree_program"
									maxLength={ 40 }
									className="form__input"
									placeholder={ t('form-field.degree_program') }
									ref={ register({ required: true }) }
								/>
								{ errors.s1_degree_program &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  intended degree  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_intended_degree">
									{ t('form-field.intended_degree') }*
								</label>
								<input
									type="text"
									name="s1_intended_degree"
									maxLength={ 100 }
									className="form__input"
									placeholder={ t('form-field.intended_degree') }
									ref={ register({ required: true }) }
								/>
								{ errors.s1_intended_degree &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  head of program  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_head_of_program">
									{ t('form-field.head_of_program') }
								</label>
								<input
									type="text"
									name="s1_head_of_program"
									maxLength={ 40 }
									className="form__input"
									placeholder={ t('form-field.head_of_program') }
									ref={ register() }
								/>
							</div>
						</Grid>

						{/* ===============================  Website URL  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s1_website">
									{ t('form-field.website') }
								</label>
								<input
									type="url"
									name="s1_website"
									maxLength={ 200 }
									className="form__input"
									placeholder='https://'
									ref={register({
										pattern: /^(http|https):/
									})}
								/>
								{ errors.s1_website &&
									<span className="form__error">
										{ t('form-error.url') }
									</span>
								}
							</div>
						</Grid>
					</Grid>

					{/*
					*
					*
					* ===============================
					* ===============================  student 2
					* ===============================
					*
					*
					*/}

					<hr />

					<p className='create-step'>2/3</p>
					<h2>{ t('form-field.student_2') }</h2>

					<Grid
						container
						spacing={ 4 }
					>

						{/* ===============================  last name  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_name">
									{ t('form-field.last_name') }*
								</label>
								<input
									type="text"
									name="s2_name"
									maxLength={ 50 }
									className="form__input"
									placeholder={ t('form-field.last_name') }
									ref={ register({ required: true }) }
								/>
								{ errors.s2_name &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  first name  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_first_name">
									{ t('form-field.first_name') }*
								</label>
								<input
									type="text"
									name="s2_first_name"
									maxLength={ 50 }
									className="form__input"
									placeholder={ t('form-field.first_name') }
									ref={ register({ required: true }) }
								/>
								{ errors.s2_first_name &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  email  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_email">
									{ t('form-field.email') }*
								</label>
								<input
									required
									id='email'
									type='email'
									name='s2_email'
									className="form__input"
									variant='outlined'
									placeholder={ t('form-field.email') }
									ref={register({
										required: true,
										pattern: /^([A-Za-z0-9_\-.]){1,}@([A-Za-z0-9_\-.]){1,}\.([A-Za-z]){2,20}$/
									})}
								/>
								{ errors.s2_email &&
									<span className="form__error">
										{ t('form-error.email') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  phone  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_phone">
									{ t('form-field.phone') }*
								</label>
								<input
									type="text"
									name="s2_phone"
									maxLength={ 100 }
									className="form__input"
									placeholder={ t('form-field.phone') }
									ref={ register({ required: true }) }
								/>
								{ errors.s2_phone &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>


						{/* ===============================  university  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_university">
									{ t('form-field.university') }*
								</label>
								<input
									type="text"
									name="s2_university"
									maxLength={ 100 }
									className="form__input"
									placeholder={ t('form-field.university') }
									ref={ register({ required: true }) }
								/>
								{ errors.s2_university &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  degree program  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_degree_program">
									{ t('form-field.degree_program') }*
								</label>
								<input
									type="text"
									name="s2_degree_program"
									maxLength={ 40 }
									className="form__input"
									placeholder={ t('form-field.degree_program') }
									ref={ register({ required: true }) }
								/>
								{ errors.s2_degree_program &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  intended degree  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_intended_degree">
									{ t('form-field.intended_degree') }*
								</label>
								<input
									type="text"
									name="s2_intended_degree"
									maxLength={ 100 }
									className="form__input"
									placeholder={ t('form-field.intended_degree') }
									ref={ register({ required: true }) }
								/>
								{ errors.s2_intended_degree &&
									<span className="form__error">
										{ t('form-error.required') }
									</span>
								}
							</div>
						</Grid>

						{/* ===============================  head of program  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_head_of_program">
									{ t('form-field.head_of_program') }
								</label>
								<input
									type="text"
									name="s2_head_of_program"
									maxLength={ 40 }
									className="form__input"
									placeholder={ t('form-field.head_of_program') }
									ref={ register() }
								/>
							</div>
						</Grid>

						{/* ===============================  Website URL  */}
						<Grid item xs={ 12 } sm={ 6 }>
							<div className="form-field">
								<label htmlFor="s2_website">
									{ t('form-field.website') }
								</label>
								<input
									type="url"
									name="s2_website"
									maxLength={ 200 }
									className="form__input"
									placeholder='https://'
									ref={register({
										pattern: /^(http|https):/
									})}
								/>
								{ errors.s2_website &&
									<span className="form__error">
										{ t('form-error.url') }
									</span>
								}
							</div>
						</Grid>
					</Grid>

					<hr />

					{/*
					*
					*
					* ===============================
					* ===============================  work / media
					* ===============================
					*
					*
					*/}

					<p className='create-step'>3/3</p>
					<h2>{ t('create.media-title') }*</h2>

					{/* ===============================  title  */}
					<div className="form-field">
						<label htmlFor="title">
							{ t('form-field.title') }*
						</label>
						<input
							type="text"
							name="title"
							maxLength={ 60 }
							className="form__input"
							placeholder={ t('form-field.title') }
							ref={ register({ required: true }) }
						/>
						{ errors.title &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>

					{/* ===============================  free message  */}
					<div className="form-field">
						<label htmlFor="free_message">
							{ t('form-field.freeMessage_tandem') }*
						</label>
						<textarea
							id='free_message'
							name='free_message'
							className="form__input"
							placeholder={ t('form-field.freeMessagePlaceholder') }
							rows={ 6 }
							maxLength={ 1000 }
							ref={ register({ required: true }) }
						/>
						{ errors.free_message &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>

					<hr className='sm'/>

					{/* ===============================  message  */}
					<div className="form-field">
						<label htmlFor="message">
							{ t('form-field.message') }
						</label>
						<textarea
							id='message'
							name='message'
							className="form__input"
							placeholder={ t('form-field.messagePlaceholder') }
							rows={ 10 }
							maxLength={ 3500 }
							ref={ register }
							onChange={ handleChange }
							disabled={ messageDisabled }
						/>
					</div>


					{/* ===============================  file  */}
					<div className="form-field">
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="file"
							name="image"
							multiple={ false }
							type="file"
							onChange={ handleChange }
							disabled={ fileDisabled }
						/>
						<label
							htmlFor="file"
						>
							<div>
								<span>
									{ t('form-field.image') }
								</span>
								<Button
									variant='outlined'
									component="span"
									name="file"
									placeholder={ t('form-field.imagePlaceholder') }
									className='file-upload-button'
									disabled={ fileDisabled }
								>
									{ image.name
										? ('Selected: ' + image.name)
										: <span className="placeholder">{ t('form-field.imagePlaceholder')  }</span>
									}
								</Button>
							</div>
						</label>
						{ image.name &&
							<button
								onClick={ removeImage }
								className='clear-input-field'
							>
								{ t('form-field.removeImage') }
							</button>
						}
					</div>


					{/* ===============================  sound  */}
					<div className="form-field">
						<label
							htmlFor="sound"
						>
							{ t('form-field.sound') }
						</label>
						<input
							type="url"
							name="sound"
							className="form__input"
							placeholder='Sound URL'
							onChange={ handleChange }
							ref={register({
								pattern: /^(http|https):/
							})}
							disabled={ soundDisabled }
						/>
						{ errors.sound &&
							<span className="form__error">
								{ t('form-error.url') }
							</span>
						}
					</div>


				{/* ===============================  video  */}
					<div className="form-field">
						<label
							htmlFor="video"
						>
							{ t('form-field.video') }
						</label>
						<input
							type="url"
							name="video"
							className="form__input"
							placeholder='Video URL'
							onChange={ handleChange }
							ref={register({
								pattern: /^(http|https):/
							})}
							disabled={ videoDisabled }
						/>
						{ errors.video &&
							<span className="form__error">
								{ t('form-error.url') }
							</span>
						}
					</div>


				{/* ===============================  legal checks  */}
				<div className="form-field">
						<label className='form__checkbox'>
						  <input
						  	type='checkbox'
						  	name='legal_01'
						  	ref={ register({ required: true }) }
						  />
						  <div className='form__checkbox-text'>
								<p>{ t('form.confirm-prize') }*</p>
							</div>
						</label>
						{ errors.legal_01 &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>

					<div className="form-field">
						<label className='form__checkbox'>
						  <input
						  	type='checkbox'
						  	name='legal_03'
						  	ref={ register({ required: true }) }
						  />
						  <div className='form__checkbox-text'>
								<p>{ t('form.confirm-tandem') }*</p>
									<a
										href='/terms-and-conditions-tandem-nautile'
										target="_blank"
									>
										<strong>{ t('pages.terms_tandem_nautile') }</strong>
									</a>
							</div>
						</label>
						{ errors.legal_03 &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>

					<div className="form-field">
						<label className='form__checkbox'>
						  <input
						  	type='checkbox'
						  	name='legal_04'
						  	ref={ register({ required: true }) }
						  />
						  <div className='form__checkbox-text'>
								<p>{ t('form.confirm') }*</p>
									<a
										href='/imprint-and-privacy'
										target="_blank"
									>
										<strong>{ t('pages.privacy') }</strong>
									</a>
									<a
										href='/terms-and-conditions'
										target="_blank"
									>
										<strong>{ t('pages.terms') }</strong>
									</a>
							</div>
						</label>
						{ errors.legal_04 &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>


					{/* ===============================  submit  */}
					<div className='align-center'>
						<Button
							variant='contained'
							color='primary'
							type='submit'
							className='submit-button'
							disabled={ isSubmitting }
						>
							{ t('form-field.submit-button') }
						</Button>
					</div>

				</form>

			</Container>
		</div>
	)
}
