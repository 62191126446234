import React, { Component } from 'react';
import { FirestoreDocument } from 'react-firestore';
import dayjs from 'dayjs';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Spinner from '../components/Spinner';

class pendingPost extends Component {

  render() {
    const { postId } = this.props.match.params;

    return (
      <div className='align-center'>
        <Container maxWidth='md'>
          <FirestoreDocument
            path={'tandemNautilePendingPosts/' + postId}
            render={({ isLoading, data }) => {
              return isLoading ? (
                <Spinner />
              ) : (
                  <div className='content-wrapper'>
                    <div className='post-detail__header'>
                      { data.title &&
                        <h1>{ data.title }</h1>
                      }
                      <p><i>{ dayjs(data.createdAt).format('DD.MM.YYYY') }</i></p>

                      { data.url &&
                        <a
                          href={ data.url }
                          target='_blank'
                          rel='noopener noreferrer'
                        ><i>{ data.url }</i></a>
                      }
                    </div>

                    { data.imageUrl &&
                      <img
                        src={ data.imageUrl }
                        alt=''
                      />
                    }

                    { data.sound &&
                      <a
                        href={ data.sound }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='lg'
                      >
                        { data.sound }
                      </a>
                    }

                    { data.video &&
                      <a
                        href={ data.video }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='lg'
                      >
                        { data.video }
                      </a>
                    }

                    { data.message &&
                      <p className='post-message'>{ data.message }</p>
                    }

                    <div className='tandem-nautile-details'>
                      <Container>
                        <Grid
                          container
                          spacing={ 4 }
                        >
                          <Grid item xs={ 12 } sm={ 6 }>
                            <h3 className="underline">Student 1 (France):</h3>
                            <p className='meta'>
                              <span>First name, Last name:</span>
                              { data.s1_first_name }, { data.s1_name }
                            </p>
                            <p className='meta'>
                              <span>Email:</span>
                              { data.s1_email || '-' }
                            </p>
                            <p className='meta'>
                              <span>Phone:</span>
                              { data.s1_phone || '-' }
                            </p>
                            <p className='meta'>
                              <span>University:</span>
                              { data.s1_university || '-' }
                            </p>
                            <p className='meta'>
                              <span>Degree program:</span>
                              { data.s1_degree_program || '-' }
                            </p>
                            <p className='meta'>
                              <span>Intended degree:</span>
                              { data.s1_intended_degree || '-' }
                            </p>
                            <p className='meta'>
                              <span>Head of program:</span>
                              { data.s1_head_of_program || '-' }
                            </p>
                            <p className='meta'>
                              <span>Website:</span>
                              { data.s1_website || '-' }
                            </p>
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 }>
                            <h3 className="underline">Student 2 (Germany):</h3>
                            <p className='meta'>
                              <span>First name, Last name:</span>
                              { data.s2_first_name }, { data.s2_name }
                            </p>
                            <p className='meta'>
                              <span>Email:</span>
                              { data.s2_email || '-' }
                            </p>
                            <p className='meta'>
                              <span>Phone:</span>
                              { data.s2_phone || '-' }
                            </p>
                            <p className='meta'>
                              <span>University:</span>
                              { data.s2_university || '-' }
                            </p>
                            <p className='meta'>
                              <span>Degree program:</span>
                              { data.s2_degree_program || '-' }
                            </p>
                            <p className='meta'>
                              <span>Intended degree:</span>
                              { data.s2_intended_degree || '-' }
                            </p>
                            <p className='meta'>
                              <span>Head of program:</span>
                              { data.s2_head_of_program || '-' }
                            </p>
                            <p className='meta'>
                              <span>Website:</span>
                              { data.s2_website || '-' }
                            </p>
                          </Grid>
                        </Grid>
                      </Container>
                      { data.freeMessage && (
                        <div className='post-detail__free-message-wrapper'>
                          <h3>Free message:</h3>
                          <div className='post-detail__free-message'>
                            <p>{ data.freeMessage }</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
            }}
          />

        </Container>
      </div>
    );
  }
}

export default pendingPost;
