import React, { useState } from 'react';
import { FirestoreCollection } from 'react-firestore';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Spinner from '../components/Spinner';
import PostAdmin from '../components/PostAdmin';
import PostTandemNautile from '../components/PostTandemNautile';

export default function Panel(props) {
  const { isAdmin } = props;
  const [tab, setTab] = useState('tandem');

  function handleTabSwitch() {
    if(tab === 'posts') {
      setTab('tandem');
    } else {
      setTab('posts');
    }
  }

  return (
    <div className='panel'>
      <Container>
        <h1>Admin Panel</h1>

        <div className='tabs'>
          <div
            className={`tabs__item ${tab !== 'posts' ? 'active' : ''}`}
            onClick={ () => handleTabSwitch() }
          >Tandem nautile posts</div>
          <div
            className={`tabs__item ${tab === 'posts' ? 'active' : ''}`}
            onClick={ () => handleTabSwitch() }
          >Standard posts</div>
        </div>

        { tab === 'tandem' && (
          <>
            <h2>Tandem nautile Posts</h2>
            <FirestoreCollection
              path='tandemNautilePendingPosts'
              // filter={['preselected', '==', 1]}
              // sort='createdAt:desc'
              render={({ isLoading, data }) => {
                if (isLoading) {
                  return (
                    <Spinner />
                  );
                } else if (data.length === 0) {
                  return <p className='empty-state'>No posts yet</p>;
                } else {
                  return (
                    <Grid
                      container
                      spacing={ 4 }
                    >
                      { data.map((post, index, requests) => (
                        <PostTandemNautile
                          key={ index }
                          post={ post }
                        />
                      ))}
                    </Grid>
                  )
                }
              }}
            />
          </>
        )}

        { tab === 'posts' && (
          <>
            <h2>Preselected Posts</h2>
            <FirestoreCollection
              path='pendingPosts'
              filter={['preselected', '==', 1]}
              sort='createdAt:desc'
              render={({ isLoading, data }) => {
                if (isLoading) {
                  return (
                    <Spinner />
                  );
                } else if (data.length === 0) {
                  return <p className='empty-state'>No posts yet</p>;
                } else {
                  return (
                    <div>
                      {data.map((post, index, requests) => (
                        <PostAdmin
                          isAdmin={ isAdmin }
                          key={ post.id }
                          post={ post }
                        />
                      ))}
                    </div>
                  )
                }
              }}
            />

            <h2 className='last'>Posts</h2>
            <FirestoreCollection
              path='pendingPosts'
              filter={['preselected', '==', 0]}
              sort='createdAt:desc'
              render={({ isLoading, data }) => {
                if (isLoading) {
                  return (
                    <Spinner />
                  );
                } else if (data.length === 0) {
                  return <p className='empty-state'>No posts yet</p>;
                } else {
                  return (
                    <div>
                      { data.map((post, index, requests) => (
                        <PostAdmin
                          isAdmin={ isAdmin }
                          key={ post.id }
                          post={ post }
                        />
                      ))}
                    </div>
                  )
                }
              }}
            />
          </>
        )}

      </Container>
    </div>
  )
}
