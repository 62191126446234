import React, { Component, Fragment } from 'react';

// ui
import IconButton from '@material-ui/core/IconButton';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';

import firebase from "@firebase/app";

class DeselectPost extends Component {
  state = {
    open: false
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  preselectPost = () => {
    const postId = this.props.postId;
    this.setState({ open: false });

    firebase
    .firestore()
      .collection("pendingPosts")
      .doc(postId)
      .update({
        preselected: 0
      });
  };
  render() {
    return (
      <Fragment>
        <IconButton
          tip="Publish post"
          onClick={ this.preselectPost }
          variant="outlined"
          color="primary"
        >
          <FavoriteOutlinedIcon />
        </IconButton>
      </Fragment>
    );
  }
}

export default (DeselectPost);
