import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeletePost from './DeletePost';
import PublishPost from './PublishPost';
import PreselectPost from './PreselectPost';
import DeselectPost from './DeselectPost';

class PostAdmin extends Component {
	render() {
		const { post } = this.props;

		return (
			<div className='panel__post'>
				<Grid
					container
					spacing={ 2 }
				>
					<Grid
						item
						xs={ 12 }
						sm={ 4 }
						md={ 3 }
					>
						<p>{ post.user }</p>
					</Grid>
					<Grid
						item
						sm={ 4 }
						md={ 3 }
					>
						<a href={`mailto: ${post.email}`}>{ post.email }</a>
					</Grid>
					<Grid
						item
						xs={12}
						sm={ 4 }
						md={ 2 }
					>
						<p><i>{ dayjs(post.createdAt).format('DD.MM.YYYY — HH:mm') }</i></p>
					</Grid>
					<Grid
						item
						sm={ 12 }
						md={ 4 }
					>
						<div className='button-wrapper'>
							{ this.props.isAdmin === true &&
								<DeletePost postId={ post.id } />
							}
							<Link
								to={`/pendingPost/${post.id}`}
								key={post.id}
							>
								<IconButton>
									<VisibilityOutlinedIcon />
								</IconButton>
							</Link>
							{ post.preselected === 0
								? <PreselectPost postId={ post.id } />
								: <DeselectPost postId= {post.id } />
							}
							{ this.props.isAdmin === true &&
								<PublishPost postId={post.id} />
							}
						</div>
					</Grid>
				</Grid>
			</div>
		)
	}
}

export default PostAdmin;
