import React from 'react'
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import LogoDFH from '../images/logo-dfh.svg';
import LogoDFK from '../images/logo-dfk.svg';
import LogoDFJK from '../images/logo-dfjw.svg';
import LogoArte from '../images/logo-arte.svg';

import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t, i18n } = useTranslation(['translation', 'welcome']);

	const changeLanguage = code => {
    i18n.changeLanguage(code);
  };

	return (
		<footer className='footer'>
			<Container>
				<div className='peephole'>
					<svg width='71px' height='169px' viewBox='0 0 71 169' version='1.1'>
				  	<g id='peephole' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				    	<g id='Group' transform='translate(1.000000, 1.000000)' stroke='#655B51'>
		            <g id='tube' transform='translate(33.610000, 84.703000)'>
		            	<path d='M35.08,81.425 L35.08,11.606 C35.08,8.94 32.93,6.778 30.27,6.778 L12.1,6.778 L12.1,4.387 C12.1,1.964 10.15,0 7.73,0 L4.37,0 C1.96,0 0,1.964 0,4.387 L0,20.929 C0,23.353 1.96,25.319 4.37,25.319 L7.73,25.319 C10.15,25.319 12.1,23.353 12.1,20.929 L12.1,18.541 L23.37,18.541 L23.37,81.53 C25.07,82.01 27,82.284 29.05,82.284 C31.24,82.284 33.3,81.968 35.08,81.425 Z' id='Path'></path>
		            </g>
		            <g id='bubble-3' transform='translate(14.020000, 65.200000)'>
		            	<path d='M5.55,11.086 C8.61,11.086 11.09,8.604 11.09,5.543 C11.09,2.482 8.61,6.21724894e-15 5.55,6.21724894e-15 C2.49,6.21724894e-15 1.12798659e-13,2.482 1.12798659e-13,5.543 C1.12798659e-13,8.604 2.49,11.086 5.55,11.086 Z' id='Path'></path>
		            </g>
		            <g id='bubble-2' transform='translate(0.000000, 37.106000)'>
		            	<path d='M9.96,19.92 C15.46,19.92 19.92,15.461 19.92,9.959 C19.92,4.458 15.46,-2.13162821e-14 9.96,-2.13162821e-14 C4.46,-2.13162821e-14 1.11910481e-13,4.458 1.11910481e-13,9.959 C1.11910481e-13,15.461 4.46,19.92 9.96,19.92 Z' id='Path'></path>
		            </g>
		            <g id='bubble-1' transform='translate(12.880000, 0.000000)'>
		            	<path d='M17.19,32.636 C26.68,32.636 34.38,25.331 34.38,16.319 C34.38,7.307 26.68,-1.77635684e-14 17.19,-1.77635684e-14 C7.7,-1.77635684e-14 0,7.307 0,16.319 C0,25.331 7.7,32.636 17.19,32.636 Z' id='Path'></path>
		            </g>
				    	</g>
				  	</g>
					</svg>
				</div>

				<div className='sctn'>
					<Grid
					  container
					  justify='space-between'
					  alignItems='flex-start'
					>
				    <Grid
				    	item
				    	xs={ 12 }
				    	xl={ 6 }
				    >
				    	<div>
							  <Link to='/about'>
							  	{ t('pages.about') }
							  </Link>
							  <Link to='/create-selection'>
							  	{ t('pages.post') }
							  </Link>
							  <Link to='/imprint-and-privacy'>
							  	{ t('pages.privacy') }
							  </Link>
							  <Link to='/terms-and-conditions'>
							  	{ t('pages.terms') }
							  </Link>
							  <Link to='/terms-and-conditions-tandem-nautile'>
							  	{ t('pages.terms_tandem_nautile') }
							  </Link>
							</div>
						  <div className='footer__languages'>
						  	<p>{ t('chooseLanguage')}</p>
					      <span
					      	className={ i18n.language === 'de' ? `footer__lang active` : `footer__lang` }
					      	onClick={() => changeLanguage('de')}
					      >
					      	{ 'De' }
					      </span>
					      <span
					      	className={ i18n.language === 'en' ? `footer__lang active` : `footer__lang` }
					     		onClick={() => changeLanguage('en')}
					     	>
					      	{ 'En' }
					      </span>
					      <span
					      	className={ i18n.language === 'fr' ? `footer__lang active` : `footer__lang` }
					     		onClick={() => changeLanguage('fr')}
					     	>
					      	{ 'Fr' }
					      </span>
				  		</div>
					  </Grid>

					  <Grid
				    	item
				    	xl={ 6 }
				    	xs={ 12 }
				    >
						  <div className='footer__logos'>
						  	<div className='footer__logo-wrapper'>
							  	<p>{ t('footer.logo-title-1')}</p>
							  	<div>
								  	<a
								  		href='https://www.dfh-ufa.org/'
								  		target='_blank'
								  		rel='noopener noreferrer'
								  		className='footer__logo'
								  	>
										  <img
										  	src={ LogoDFH }
										  	alt='DFH Logo'
										  />
										</a>
										<a
											href='http://www.dfkr.org/'
											target='_blank'
											rel='noopener noreferrer'
											className='footer__logo'
										>
										  <img
										  	src={ LogoDFK }
										  	alt='DFK Logo'
										  />
									  </a>
									  <a
									  	href='https://www.ofaj.org/'
									  	target='_blank'
									  	rel='noopener noreferrer'
									  	className='footer__logo'
									  >
										  <img
										  	src={ LogoDFJK }
										  	alt='DFJK Logo'
										  />
									  </a>
									</div>
								</div>
							  <div className='footer__logo-wrapper'>
							  	<p>{ t('footer.logo-title-2')}</p>
							  	<div>
									  <a
									  	href='https://www.arte.tv/'
									  	target='_blank'
									  	rel='noopener noreferrer'
									  	className='footer__logo'
									  >
										  <img
										  	src={ LogoArte }
										  	alt='Arte Logo'
										  />
									  </a>
									</div>
								</div>
							</div>
						</Grid>
			    </Grid>
			    <Grid
					  container
					  justify='space-between'
					  alignItems='baseline'
					>
						<Grid item>
							<div className='footer__copyright'>
								<p>© Université franco-allemande | Deutsch-Französische Hochschule, alle Rechte vorbehalten</p>
					    </div>
						</Grid>
			    </Grid>
		  	</div>
			</Container>
		</footer>
	);
};

export default Footer;
