import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';

// ui
import Container from '@material-ui/core/Container';

class privacy extends Component {
	render() {
		const { t } = this.props;

		return (
			<div className='sctn sctn--nt'>
				<Container maxWidth='md'>
					<div className="content-wrapper">
			      <div className='align-center'>
							<h1>
				      	{ t('pages.privacy') }
				      </h1>
				    </div>
				    <div>
							<p>
								{ t('privacy-policy') }
							</p>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}

const MyComponent = withTranslation()(privacy);
// export default about;

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyComponent />
    </Suspense>
  );
}
