import React, { Component, Fragment } from 'react';

// ui
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// redux
// import { connect } from 'react-redux';
// import { publishPost } from '../redux/actions/dataActions';

import firebase from '@firebase/app';

class UnpublishPost extends Component {
  state = {
    open: false
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  unpublishPost = (e) => {
    e.stopPropagation();

    const postId = this.props.postId;
    // this.props.publishPost(postId);
    this.setState({ open: false });

    firebase
      .firestore()
      .collection('posts')
      .doc(postId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          firebase
            .firestore()
            .collection('pendingPosts')
            .doc(require('uuid/v4')())
            .set(doc.data())
            .then(function () {
              firebase
                .firestore()
                .collection('posts')
                .doc(postId)
                .delete()
            })
        }
      });
  };
  render() {
    return (
      <Fragment>
        <IconButton
          tip='Unpublish post'
          onClick={(e) => {
             e.stopPropagation();
             this.handleOpen()
          }}
          color='secondary'
        >
          <HighlightOffIcon />
        </IconButton>
        <Modal
          className='modal'
          open={ this.state.open }
          onClose={ this.handleClose }
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <div className='modal__inner'>
            <h2>Are you sure you want to <strong>unpublish</strong> this post?</h2>

            <div className='modal__buttons'>
              <Button
                onClick={ this.handleClose }
                variant='contained'
              >
                Cancel
              </Button>
              <Button
                onClick={ this.unpublishPost }
                color='primary'
                variant='contained'
              >
                Unpublish
              </Button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default UnpublishPost;
