import React, { Component, Fragment } from 'react';

// ui
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';

import firebase from '@firebase/app';

class PreselectPost extends Component {
  state = {
    open: false
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  preselectPost = () => {
    const postId = this.props.postId;
    this.setState({ open: false });

    firebase
    .firestore()
      .collection('pendingPosts')
      .doc(postId)
      .update({
        preselected: 1
      });
  };
  render() {
    return (
      <Fragment>
        <IconButton
          tip='Publish Scream'
          onClick={ this.preselectPost }
          variant='outlined'
          color='primary'
        >
          <FavoriteBorderOutlinedIcon />
        </IconButton>
      </Fragment>
    );
  }
}

export default (PreselectPost);
