import React, { Component, Suspense } from 'react';

import { withTranslation } from 'react-i18next';

class notFound extends Component {
	render() {
		const { t } = this.props;

		return (
			<div className='not-found'>
				<h1 className='center'>{'404'}</h1>
				<p className='center'>{ t('notFound') }</p>
			</div>
		)
	}
}

const MyComponent = withTranslation()(notFound);
// export default about;

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyComponent />
    </Suspense>
  );
}
