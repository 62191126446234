import React from "react";
// import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';
import socialImage from '../images/social.jpg'

export default function Head() {

  return (
    <MetaTags>
      <meta charSet="utf-8" />
      <title>nautile.cc</title>
      <link rel="canonical" href="https://www.nautile.cc" />

      <meta property="og:type" content="website"/>
      <meta
        property="og:url"
        content="%PUBLIC_URL%"
      />
      <meta
        property="og:image"
        content={ socialImage }
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        property="og:title"
        content="nautile.cc"
      />
      <meta
        property="og:description"
        content=""
      />

      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:site" content="https://www.twitter.com/"/>
      <meta
        name="twitter:title"
        content="nautile.cc"
      />
      <meta
        name="twitter:description"
        content=""
      />
      <meta
        name="twitter:domain"
        content="%PUBLIC_URL%"
      />
    </MetaTags>
  );
};
