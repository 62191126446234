import React, { useState } from 'react';
import firebase from "@firebase/app";
import 'firebase/storage'
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
// import { app } from "../Firebase";

const storage = firebase.storage();

export default function Create() {
	const [image, setImage] = useState('');
	const [progress, setProgress] = useState(0);
	const { t } = useTranslation();

	const [soundDisabled, setDisabledSound] = useState(false);
	const [videoDisabled, setDisabledVideo] = useState(false);
	const [messageDisabled, setDisabledMessage] = useState(false);
	const [fileDisabled, setDisabledFile] = useState(false);

	const {
    register,
    handleSubmit,
    errors,
    // setError,
    // clearError,
    formState: { isSubmitting }
  } = useForm();
  const history = useHistory();

	function handleImageChange(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			// this.setState({ image });
			setImage(image);
		}
	}

	function enableAllMedia() {
		setDisabledMessage(false);
		setDisabledVideo(false);
		setDisabledSound(false);
		setDisabledFile(false);
	}

	function removeImage() {
		setImage('');
		enableAllMedia();
	}

  function handleChange(e) {

  	if (e.target.name === 'video' && e.target.value !== '') {
			setDisabledMessage(true);
			setDisabledSound(true);
			setDisabledFile(true);
  	} else if (e.target.name === 'sound' && e.target.value !== '') {
			setDisabledMessage(true);
			setDisabledVideo(true);
			setDisabledFile(true);
  	} else if(e.target.name === 'message' && e.target.value !== '') {
			setDisabledVideo(true);
			setDisabledFile(true);
			setDisabledSound(true);
  	} else if(e.target.name === 'image' && e.target.files.length > 0) {
			setDisabledVideo(true);
			setDisabledMessage(true);
			setDisabledSound(true);
  	} else {
  		enableAllMedia();
  	}

  	if (e.target.files) {
  		if(e.target.files[0].size > 2000000){
      	alert("File is too big! Max 2MB please!");
      	enableAllMedia();
    	} else {
    		handleImageChange(e);
    	}
  	}
  }

	function handleUpload(data) {
		const date = parseInt(Date.now());
		const uploadTask = storage.ref(`uploads/${date}/${image.name}`).put(image);
		uploadTask.on(
			"state_changed",
			snapshot => {
				const currentProgress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(currentProgress);
				console.log(currentProgress, progress);
			},
			error => {
				console.log(error);
			},
			() => {
				storage
					.ref("uploads")
					.child(date + '/' + image.name)
					.getDownloadURL()
					.then(imageUrl => {
						saveToFirestore(data, imageUrl);
					});
			}
		);
	};

	function saveToFirestore(data, imageUrl) {
		firebase
		.firestore()
		.collection("pendingPosts")
		.doc(require("uuid/v4")())
		.set({
			title: data.title,
			user: data.name,
			email: data.email,
			location: data.location || '',
			url: data.url || '',
			imageUrl: imageUrl || '',
			message: data.message || '',
			sound: data.sound || '',
			video: data.video || '',
			freeMessage: data.freeMessage || '',
			preselected: 0,
			createdAt: parseInt(Date.now()),
		});
		history.push('/success');
	}

	const onSubmit = data => {
		if (image === '') {
			saveToFirestore(data);
		} else {
			handleUpload(data);
		}
	};

	return (
		<div>
			<Container maxWidth='sm'>
				<div className="headline-wrapper">
					<h1 className='center'>{ t('create.create-title') }</h1>
					<p className='center'>{ t('create.create-subtitle') }</p>
				</div>

				<p className='create-step'>1/2</p>
				<h2>{ t('create.create-title') }</h2>

				<form
					noValidate
					onSubmit={ handleSubmit(onSubmit) }
				>

					{/* ===============================  name  */}
					<div className="form-field">
						<label
							htmlFor="name"
						>
							{ t('form-field.name') }*
						</label>
						<input
							type="text"
							name="name"
							maxLength={ 50 }
							className="form__input"
							placeholder={ t('form-field.name') }
							ref={ register({ required: true }) }
						/>
						{ errors.name &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>

					{/* ===============================  email  */}
					<div className="form-field">
						<label
							htmlFor="email"
						>
							{ t('form-field.email') }*
						</label>
						<input
							required
							id='email'
							type='email'
							name='email'
							className="form__input"
							variant='outlined'
							placeholder={ t('form-field.email') }
							ref={register({
								required: true,
								pattern: /^([A-Za-z0-9_\-.]){1,}@([A-Za-z0-9_\-.]){1,}\.([A-Za-z]){2,20}$/
							})}
						/>
						{ errors.email &&
							<span className="form__error">
								{ t('form-error.email') }
							</span>
						}
					</div>

				{/* ===============================  location  */}
					<div className="form-field">
						<label
							htmlFor="location"
						>
							{ t('form-field.location') }*
						</label>
						<input
							type="text"
							name="location"
							maxLength={ 50 }
							className="form__input"
							placeholder='Paris, France'
							ref={ register({ required: true }) }
						/>
						{ errors.location &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>


					{/* ===============================  Website URL  */}
					<div className="form-field">
						<label
							htmlFor="url"
						>
							{ t('form-field.website') }
						</label>
						<input
							type="url"
							name="url"
							maxLength={ 40 }
							className="form__input"
							placeholder='https://your-website.com'
							ref={register({
								pattern: /^(http|https):/
							})}
						/>
						{ errors.url &&
							<span className="form__error">
								{ t('form-error.url') }
							</span>
						}
					</div>

					{/* ===============================  free message  */}
					<div className="form-field">
						<label
							htmlFor="free-message"
						>
							{ t('form-field.freeMessage') }
						</label>
						<textarea
							id='free-message'
							name='freeMessage'
							className="form__input"
							placeholder={ t('form-field.freeMessagePlaceholder') }
							rows={ 10 }
							maxLength={ 1000 }
							ref={ register }
						/>
					</div>

					<hr />

					<p className='create-step'>2/2</p>
					<h2>{ t('create.media-title') }*</h2>

					{/* ===============================  title  */}
					<div className="form-field">
						<label
							htmlFor="title"
						>
							{ t('form-field.title') }*
						</label>
						<input
							type="text"
							name="title"
							maxLength={ 60 }
							className="form__input"
							placeholder={ t('form-field.title') }
							ref={ register({ required: true }) }
						/>
						{ errors.title &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>

					{/* ===============================  message  */}
					<div className="form-field">
						<label
							htmlFor="message"
						>
							{ t('form-field.message') }
						</label>
						<textarea
							id='message'
							name='message'
							className="form__input"
							placeholder={ t('form-field.messagePlaceholder') }
							rows={ 10 }
							maxLength={ 3500 }
							ref={ register }
							onChange={ handleChange }
							disabled={ messageDisabled }
						/>
					</div>


					{/* ===============================  file  */}
					<div className="form-field">
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="file"
							name="image"
							multiple={ false }
							type="file"
							onChange={ handleChange }
							disabled={ fileDisabled }
						/>
						<label
							htmlFor="file"
						>
							<div>
								<span>
									{ t('form-field.image') }
								</span>
								<Button
									variant='outlined'
									component="span"
									name="file"
									placeholder={ t('form-field.imagePlaceholder') }
									className='file-upload-button'
									disabled={ fileDisabled }
								>
									{ image.name
										? ('Selected: ' + image.name)
										: <span className="placeholder">{ t('form-field.imagePlaceholder')  }</span>
									}
								</Button>
							</div>
						</label>
						{ image.name &&
							<button
								onClick={ removeImage }
								className='clear-input-field'
							>
								{ t('form-field.removeImage') }
							</button>
						}
					</div>


					{/* ===============================  sound  */}
					<div className="form-field">
						<label
							htmlFor="sound"
						>
							{ t('form-field.sound') }
						</label>
						<input
							type="url"
							name="sound"
							className="form__input"
							placeholder='Sound URL'
							onChange={ handleChange }
							ref={register({
								pattern: /^(http|https):/
							})}
							disabled={ soundDisabled }
						/>
						{ errors.sound &&
							<span className="form__error">
								{ t('form-error.url') }
							</span>
						}
					</div>


				{/* ===============================  video  */}
					<div className="form-field">
						<label
							htmlFor="video"
						>
							{ t('form-field.video') }
						</label>
						<input
							type="url"
							name="video"
							className="form__input"
							placeholder='Video URL'
							onChange={ handleChange }
							ref={register({
								pattern: /^(http|https):/
							})}
							disabled={ videoDisabled }
						/>
						{ errors.video &&
							<span className="form__error">
								{ t('form-error.url') }
							</span>
						}
					</div>


				{/* ===============================  legal check  */}
					<div className="form-field">
						<label className='form__checkbox'>
						  <input
						  	type='checkbox'
						  	name='legal'
						  	ref={ register({ required: true }) }
						  />
						  <div className='form__checkbox-text'>
								<p>
									{ t('form.confirm') }*
								</p>
									<a
										href='/imprint-and-privacy'
										target="_blank"
									>
										<strong>{ t('pages.privacy') }</strong>
									</a>
									<a
										href='/terms-and-conditions'
										target="_blank"
									>
										<strong>{ t('pages.terms') }</strong>
									</a>
							</div>
						</label>
						{ errors.legal &&
							<span className="form__error">
								{ t('form-error.required') }
							</span>
						}
					</div>


					{/* ===============================  submit  */}
					<div className='align-center'>
						<Button
							variant='contained'
							color='primary'
							type='submit'
							className='submit-button'
							disabled={ isSubmitting }
						>
							{ t('form-field.submit-button') }
						</Button>
					</div>

				</form>

			</Container>
		</div>
	)
}
