import React, { Component, Suspense } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import institutImage from '../images/institut-francais-deutschland-logo.svg';

class terms extends Component {
	render() {
		const { t } = this.props;

		return (
			<div className='sctn sctn--nt'>
				<Container maxWidth='md'>
					<div className="content-wrapper">
			      <div className='align-center'>
							<h1>
				      	{ t('pages.terms_tandem_nautile') }
				      </h1>
				    </div>
				    <div>
							<Trans i18nKey="terms-and-conds-tandem-nautile"/><br/>
							<Grid
								container
								spacing={ 3 }
								direction="row"
								className='helper-grid'
							>
								<Grid
									item
									sm={ 6 }
									xs={ 12 }
								>
									<p>
										{ t('common.contact') }:<br/>
										<a href="mailto:nicole.martinez@institutfrancais.de">
											nicole.martinez@institutfrancais.de
										</a>
									</p>
								</Grid>
								<Grid
									item
									sm={ 6 }
									xs={ 12 }
								>
									<a
										href='https://www.institutfrancais.de/'
										target='blank'
										ref="nofollow"
									>
										<img
											src={ institutImage }
											alt='institut francais deutschland logo'
											width='180'
											styles='display: block;'
										/>
									</a>
								</Grid>
							</Grid>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}

const MyComponent = withTranslation()(terms);
// export default about;

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyComponent />
    </Suspense>
  );
}
