import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import institutImage from '../images/institut-francais-deutschland-logo.svg';

export default function Component() {
  const { t } = useTranslation();

  return (
    <div>
      <Container maxWidth='md'>
        <h1 className='center'>{ t('pages.post') }</h1>
        <Grid
          container
          spacing={ 4 }
        >
          <Grid
            className='flex'
            item
            xs={ 12 }
            sm={ 6 }
          >
            <div className='content-wrapper space-between'>
              <h2>{ t('create_selection.tandem_text') }</h2>
              <Grid
                container
                justify="center"
                alignItems="flex-end"
              >
                <Grid
                  item
                  xs={ 6 }
                >
                  <Link
                    to='/create-tandem-nautile'
                    className='btn'
                  >
                    <Button
                      variant='contained'
                      color='primary'
                    >
                      { t('create_selection.tandem_btn') }
                    </Button>
                  </Link>
                </Grid>
                <Grid
                  xs={ 6 }
                  item
                >
                  <img
                    src={ institutImage }
                    alt='institut francais deutschland logo'
                    width='100'
                    className='align-right'
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            className='flex'
            item
            xs={ 12 }
            sm={ 6 }
          >
            <div className='content-wrapper space-between'>
              <h2>{ t('create_selection.post_text') }</h2>
              <Link
                to='/create-post'
                className='btn'
              >
                <Button
                  variant='contained'
                  color='primary'
                >
                  { t('create_selection.post_btn') }
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
