import React from "react";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { app } from "../Firebase";

class login extends React.Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: ''
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const userData = {
			email: this.state.email,
			password: this.state.password
		};

		app
			.auth()
			.signInWithEmailAndPassword(userData.email, userData.password)
			.catch(error => {
				alert("error"); // TODO implement nicer ui for error
			});
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	render() {
		// const { UI: { loading } } = this.props;

		app.auth().onAuthStateChanged((user) => {
			if(user) {
				this.props.history.push('/panel');
			}
		});

		return (
			<div>
				<Container maxWidth='sm'>
					<h1>Login</h1>
					<form
						noValidate
						className="form"
						onSubmit={this.handleSubmit}
					>

						<div className="form-field">
							<label htmlFor="email">
								E-Mail
							</label>
					    <TextField
								type='email'
								name='email'
								placeholder='Enter email'
								value={this.state.email}
								onChange={this.handleChange}
					    	id="email"
					    	variant='outlined'
	        			required
					    />
					  </div>

					  <div className="form-field">
							<label htmlFor="email">
								Password
							</label>
							<TextField
								type='password'
								name='password'
								variant='outlined'
								placeholder='Password'
								value={this.state.password}
								onChange={this.handleChange}
							/>
						</div>

						<div className='align-center'>
							<Button
								variant='contained'
								color='primary'
								type='submit'
							>
								Submit
							</Button>
						</div>
					</form>
				</Container>
			</div>
		)
	}
}

export default login;
