import React, { Component, Suspense } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import institutImage from '../images/institut-francais-deutschland-logo.svg';
class about extends Component {

	render() {
		const { t } = this.props;

		return (
			<div className='sctn sctn--nt'>
				<Container maxWidth='md'>
					<div className='content-wrapper'>
			      <div className='align-center'>
							<h1>
				      	{ t('tandem-nautile.title') }
				      </h1>
							<h2>
				      	{ t('tandem-nautile.sub-title') }
				      </h2>
				    </div>
				    <div>
				      <Grid
				      	container
				      	spacing={ 3 }
				      >
					      <Grid
					      	item
					      	sm={ 12 }
					      >
									<p><strong>
										{ t('tandem-nautile.pre-title') }
									</strong></p>
									<p>
				        		{ t('tandem-nautile.text1') }
				      		</p>
									<p>
				        		{ t('tandem-nautile.text2') }
				      		</p>
									<p>
				        		{ t('tandem-nautile.text3') }
				      		</p>
									<p>
										<Trans i18nKey="tandem-nautile.text4"/>
				      		</p>
									<p>
										<Trans i18nKey="tandem-nautile.text5"/>
				      		</p>
									<p>
										<Trans i18nKey="tandem-nautile.text6"/>
				      		</p>
									<Grid
										container
										spacing={ 3 }
										direction="row"
										className='helper-grid'
									>
										<Grid
											item
											sm={ 6 }
											xs={ 12 }
										>
											<p>
												{ t('common.contact') }:<br/>
												<a href="mailto:nicole.martinez@institutfrancais.de">
													nicole.martinez@institutfrancais.de
												</a>
											</p>
										</Grid>
										<Grid
											item
											sm={ 6 }
											xs={ 12 }
										>
											<a
												href='https://www.institutfrancais.de/'
												target='blank'
												ref="nofollow"
											>
												<img
													src={ institutImage }
													alt='institut francais deutschland logo'
													width='180'
													styles='display: block;'
												/>
											</a>
										</Grid>
									</Grid>
									<div className='center'>
										<a href="/create-tandem-nautile">
											<Button
												variant='contained'
												color='primary'
											>
												{ t('tandem-nautile.btn') }
											</Button>
										</a>
									</div>
				      	</Grid>
				    	</Grid>
				  	</div>
				  </div>
				</Container>
			</div>
		)
	}
}

const MyComponent = withTranslation()(about);
// export default about;

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback='loading'>
      <MyComponent />
    </Suspense>
  );
}
