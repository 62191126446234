import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Spinner() {
	return (
		<Grid
		  container
		  direction='row'
		  justify='center'
		  alignItems='center'
		  className='spinner'
		>
			<CircularProgress />
		</Grid>
	)
};
