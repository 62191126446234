import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import UnpublishPost from './UnpublishPost';

class Post extends Component {

	render() {
		const { post, isAdmin } = this.props;

		return (
			<Grid
				item
				xs={ 12 }
				sm={ 6 }
				md={ 4 }
				lg={ 3 }
			>
				<div className='post__wrapper'>

					{/* unpublish */}
					{ isAdmin &&
						<div className='post__unpublish'>
							<UnpublishPost
								postId={ post.id }
							/>
						</div>
					}

					{/* post link */}
					<Link
						to={`/tandemNautilePendingPost/${post.id}`}
						className='post'
						key={ post.id }
					>
						<div className='post__inner'>

							<div className={`
								post__media
								${ post.message ? 'post__media--has-text' : '' }
								${ post.imageUrl ? 'post__media--has-img' : '' }`
							}>

								{ post.message && (
									<p>{ post.message }</p>
								)}

								{ post.imageUrl &&
									<div className='post__image'>
										<img
											src={ post.imageUrl }
											alt={ post.title }
											className='lazyload'
										/>
									</div>
								}

								{ post.video && (
									<div className='post__video'>
										{/*<p>{ post.video }</p>*/}
									</div>
								)}

								{ post.sound && (
									<div className='post__sound'>
										{/*<p>{ post.sound }</p>*/}
									</div>
								)}
							</div>
							<div className='post__meta'>
								<div>
									<p className='post__title'>
										{ post.title }
									</p>
									<p className='post__name'>
										{ post.s1_university } — { post.s2_university }
									</p>
								</div>
                <div className='post__bottom'>
									<p className='post__location'>
										{ post.s1_name } & { post.s2_name }
									</p>
                  <p className='post__date'>
                    { dayjs(post.createdAt).format('DD.MM.YYYY') }
                  </p>
                </div>
							</div>
						</div>
					</Link>
				</div>
			</Grid>
		)
	}
}

export default Post;
