import React, { Component, Fragment } from 'react';

// ui
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

// redux
// import { connect } from 'react-redux';
// import { publishPost } from '../redux/actions/dataActions';

import firebase from '@firebase/app';

class PublishPost extends Component {
  state = {
    open: false
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  publishPost = () => {
    const postId = this.props.postId;
    // this.props.publishPost(postId);
    this.setState({ open: false });

    firebase
      .firestore()
      .collection('pendingPosts')
      .doc(postId)
      .update({publishedAt: Date.now()});

    firebase
      .firestore()
      .collection('pendingPosts')
      .doc(postId)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          firebase
            .firestore()
            .collection('posts')
            .doc(require('uuid/v4')())
            .set(doc.data())
            .then(function () {
              firebase
                .firestore()
                .collection('pendingPosts')
                .doc(postId)
                .delete()
            })
        }
      });
  };
  render() {
    return (
      <Fragment>
        <Button
          tip='Publish post'
          onClick={ this.handleOpen }
          variant='outlined'
          color='primary'
        >
          Publish
        </Button>
        <Modal
          className='modal'
          open={ this.state.open }
          onClose={ this.handleClose }
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <div className='modal__inner'>
            <h2>Are you sure you want to <strong>publish</strong> this post?</h2>

            <div className='modal__buttons'>
              <Button
                onClick={ this.handleClose }
                variant='contained'
              >
                Cancel
              </Button>
              <Button
                onClick={ this.publishPost }
                color='primary'
                variant='contained'
              >
                Publish
              </Button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default PublishPost;
