import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { FirestoreCollection } from 'react-firestore';
import Post from '../components/Post';
import Head from '../components/Head';
import Spinner from '../components/Spinner';
import { app, db } from '../Firebase';

export default function Home(isAdmin) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [posts, setPosts] = useState([]);
  const [lastKey, setLastKey] = useState('');
  const [nextPosts_loading, setNextPostsLoading] = useState(false);
  const { t } = useTranslation();

  async function postsFirstBatch() {
    try {
      const data = await db
        .collection('posts')
        .orderBy('createdAt', 'desc')
        .limit(40)
        .get();

      let posts = [];
      let lastKey = '';
      data.forEach((doc) => {
        posts.push({
          ...doc.data(),
          id: doc.id,
        });
        lastKey = doc.data().createdAt;
      });

      return { posts, lastKey };
    } catch (e) {
      console.log(e);
    }
  }

  async function postsNextBatch(key) {
    try {
      const data = await db
        .collection('posts')
        .orderBy('createdAt', 'desc')
        .startAfter(key)
        .limit(20)
        .get();

      let posts = [];
      let lastKey = '';
      data.forEach((doc) => {
        posts.push({
          ...doc.data(),
          id: doc.id,
        });
        lastKey = doc.data().createdAt;
      });
      return { posts, lastKey };
    } catch (e) {
      console.log(e);
    }
  }

  const fetchMorePosts = (key) => {
    if (key) {
      setNextPostsLoading(true);
      postsNextBatch(key)
        .then((res) => {
          setLastKey(res.lastKey);
          // add new posts to old posts
          setPosts(posts.concat(res.posts));
          setNextPostsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setNextPostsLoading(false);
        });
    }
  };

  useEffect(() => {
    if(app.auth().currentUser != null) {
      setIsLoggedIn(true);
    }
    postsFirstBatch()
    .then((res) => {
      setPosts(res.posts);
      setLastKey(res.lastKey);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  console.log('posts = ', posts)

  return (
    <div>
      <Head />
      <Container>
        <div className='banner'>
          <p>{ t('home.banner-text') }</p>
          <Link
            to='/tandem-nautile'
            className='btn'
          >
            <Button
              variant='contained'
              color='primary'
            >
              { t('home.banner-btn') }
            </Button>
          </Link>
        </div>

        { posts.length === 0 && (
          <Spinner />
        )}

        { posts.length > 0 && (
          <Grid
            container
            spacing={ 4 }
          >
            { posts.map((post, index) => (
              <Post
                key={ index }
                post={ post }
                isAdmin={ isLoggedIn && isAdmin }
              />
            ))}
          </Grid>
        )}

        { posts.length > 0 && (
          <div style={{ textAlign: 'center' }}>

            { nextPosts_loading && (
              <Spinner />
            )}

            { lastKey && (
              <div className='load-more-wrapper'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => fetchMorePosts(lastKey)}
                >
                  { t('common.more-posts') }
                </Button>
              </div>
            )}

          </div>
        )}
      </Container>
    </div>
  )
}
