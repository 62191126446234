import React, { Component } from 'react';

// ui
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

// redux
// import { connect } from 'react-redux';
// import { deletePost } from '../redux/actions/dataActions';

import firebase from '@firebase/app';

class DeletePost extends Component {
  state = {
    open: false
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  deletePost = () => {
    // this.props.deletePost(this.props.postId);
    this.setState({ open: false });
    firebase
      .firestore()
      .collection('pendingPosts')
      .doc(this.props.postId)
      .delete()
  };
  render() {
    return (
      <div>
        <IconButton
          tip='Delete post'
          onClick={ this.handleOpen }
          color='secondary'
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
        <Modal
          className='modal'
          open={ this.state.open }
          onClose={ this.handleClose }
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <div className='modal__inner'>
            <h2>Are you sure you want to <strong>delete</strong> this post?</h2>

            <div className='modal__buttons'>
              <Button
                onClick={ this.handleClose }
                variant='contained'
              >
                Cancel
              </Button>
              <Button
                onClick={ this.deletePost }
                color='secondary'
                variant='contained'
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DeletePost;
