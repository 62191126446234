import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

class success extends Component {
	render() {
		const { t } = this.props;

		return (
			<div className='align-center'>
				<Container maxWidth='sm'>
					<h1>{ t('success-title') }</h1>
					<h2>{ t('success-message') }</h2>
					<Link
						to='/'
						className='header__left'
					>
						<Button
							variant='outlined'
							color='primary'
							type='submit'
						>
							{ t('success-button') }
						</Button>
					</Link>
				</Container>
			</div>
		)
	}
}

const MyComponent = withTranslation()(success);
// export default about;

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
  return (
    <Suspense fallback="loading">
      <MyComponent />
    </Suspense>
  );
}
